import React from "react"
import NewsletterForm from "../components/NewsletterForm"
import CommunityBecomeAnAmbassador from "../sections/community/CommunityBecomeAnAmbassador"
import CommunityEarlyAdopters from "../sections/community/CommunityEarlyAdopters"
import CommunityHelmet from "../sections/community/CommunityHelmet"
import CommunityHero from "../sections/community/CommunityHero"
import CommunityLearnMore from "../sections/community/CommunityLearnMore"
import CommunityShop from "../sections/community/CommunityShop"
import CommunitySocialMedia from "../sections/community/CommunitySocialMedia"
import Layout from "../templates/Layout"

import BGImg1 from "../assets/BackgroundImages/Community/Horizen_Community_sections_bg1.png"
import BGImg2 from "../assets/BackgroundImages/Community/Horizen_Community_sections_bg2.png"
import BugBounty from "../sections/community/CommunityBugBounty"

const Community = () => {
    return (
        <Layout>
            <div className="bg-horizen-hp-bg space-y-24 lg:space-y-48">
                <CommunityHelmet />
                <CommunityHero />
                <div className="!-mt-[80px] lg:!-mt-[60px]">
                    <CommunityEarlyAdopters />
                </div>
                <BugBounty />
                <CommunityBecomeAnAmbassador />
                <div
                    className="space-y-24 lg:space-y-48 bg-no-repeat"
                    style={{
                        backgroundImage: `url(${BGImg1})`,
                        backgroundSize: "100% 700px",
                    }}
                >
                    <CommunityLearnMore />
                    <CommunitySocialMedia />
                </div>
                <div
                    className="space-y-24 lg:space-y-48 bg-no-repeat  [background-position-x:center] bg-cover lg:[background-size:100%_1036px] [background-position-y:-200px]"
                    style={{
                        backgroundImage: `url(${BGImg2})`,
                    }}
                >
                    <CommunityShop />
                    <NewsletterForm />
                </div>
            </div>
        </Layout>
    )
}

export default Community
