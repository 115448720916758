import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import NormalPageHero from "../../components/NormalPageHero"
import CommunityHeroBackground from "../../assets/BackgroundImages/Community/Horizen_Community_hero_background.png"

const CommunityHero = () => {
    const { formatMessage } = useIntl()

    return (
        <section
            className="pt-[9rem] md:pt-[calc(4rem+150px)] pb-[100px] bg-cover [background-position:50%_100%] bg-no-repeat"
            style={{
                backgroundImage: `url(${CommunityHeroBackground})`,
            }}
        >
            <NormalPageHero
                titleKey={formatMessage({ id: "community.hero.heading" })}
                subtitleKey={formatMessage({ id: "community.hero.bodyText" })}
            />
        </section>
    )
}

export default CommunityHero
