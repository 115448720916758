import React from "react"
import ContentContainer from "../../templates/ContentContainer"
import AmbassadorsImg from "../../assets/BackgroundImages/Community/Horizen_Community_ambassadors.png"
import Typography from "../../components/Typography"
import ButtonCustomContent from "../../components/ButtonCustomContent"

const CommunityBecomeAnAmbassador = () => {
    return (
        <section className="border-y border-white">
            <ContentContainer>
                <div className="grid lg:grid-cols-2 gap-y-8 gap-x-5 min-h-[455px] h-full">
                    <div>
                        <img
                            src={AmbassadorsImg}
                            alt="Ambassadors"
                            className="h-full object-cover max-h-[240px] sm:max-h-[330px] lg:max-h-[455px] mx-auto sm:mx-0"
                        />
                    </div>
                    <div className="flex flex-col justify-center pb-12 lg:pb-0 lg:pl-16">
                        <Typography.H2>Become an Ambassador</Typography.H2>
                        <Typography.Body1 className="max-w-[450px] mt-3">
                            Ambassadors are Web3 believers and game-changers
                            from all walks of life. If you are passionate about
                            Web3 and Horizen, you can become an Ambassador and
                            help grow the Horizen ecosystem around the globe!
                        </Typography.Body1>
                        <ButtonCustomContent
                            as="internal-link"
                            href="/ambassadors/"
                            classname="mt-8"
                        >
                            FIND OUT HOW TO JOIN
                        </ButtonCustomContent>
                    </div>
                </div>
            </ContentContainer>
        </section>
    )
}

export default CommunityBecomeAnAmbassador
