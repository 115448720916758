import React from "react"
import Button from "../../components/Button"
import ContentContainer from "../../templates/ContentContainer"
import Typography from "../../components/Typography"
import BountyLogo from "../../assets/BountyLogo"

const BugBounty = () => {
    return (
        <div className="text-white">
            <ContentContainer>
                <Typography.H2 className="mb-8">
                    Participate in Bug Bounty Program
                </Typography.H2>
                <div className="border px-8 md:px-16 py-10 flex flex-col lg:flex-row lg:items-center space-y-8 lg:space-y-0 relative">
                    <div className="hidden lg:block absolute right-4">
                        <BountyLogo />
                    </div>
                    <div className="flex flex-col lg:w-2/4">
                        <span className="text-[22px] font-bold">
                            Earn up to $75,000 in bounty rewards!
                        </span>
                        <span className="text-lg text-horizen-content-grey">
                            Contribute to the security and development of
                            Horizen’s
                            <br />
                            EVM-compatible smart contracting platform.
                        </span>
                    </div>
                    <div>
                        <Button
                            label="PARTICIPATE"
                            href="https://immunefi.com/bounty/horizen/"
                            openNewTab
                        />
                    </div>
                </div>
            </ContentContainer>
        </div>
    )
}

export default BugBounty
