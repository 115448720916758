import React from "react"
import ButtonCustomContent from "../../components/ButtonCustomContent"
import Typography from "../../components/Typography"
import ContentContainer from "../../templates/ContentContainer"
import ShopImg from "../../assets/BackgroundImages/Community/Horizen_Community_store.png"

const CommunityShop = () => {
    return (
        <section>
            <ContentContainer>
                <div className="grid md:grid-cols-[3.5fr,6.5fr] gap-y-12 gap-x-5">
                    <div className="flex flex-col justify-center">
                        <Typography.H2 className="max-w-[270px]">
                            Put some ZEN in Your Style!
                        </Typography.H2>
                        <ButtonCustomContent
                            as="external-link"
                            href="https://store.horizen.io/"
                            classname="mt-8 lg:mt-24"
                            openNewTab
                        >
                            SHOP NOW
                        </ButtonCustomContent>
                    </div>
                    <div>
                        <img src={ShopImg} alt="Swag" />
                    </div>
                </div>
            </ContentContainer>
        </section>
    )
}

export default CommunityShop
