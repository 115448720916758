/* eslint-disable react/no-array-index-key */
import React from "react"
import ButtonCustomContent from "../../components/ButtonCustomContent"
import Typography from "../../components/Typography"
import ContentContainer from "../../templates/ContentContainer"

interface SocialMediaItemProps {
    icon: string
    title: string
    subtitle?: string
    link: string
}

const SocialMediaItem: React.FC<SocialMediaItemProps> = ({
    icon,
    link,
    title,
    subtitle,
}) => {
    return (
        <ButtonCustomContent
            capitalLetters={false}
            as="external-link"
            openNewTab
            href={link}
            variant="gradient"
            classname="py-10 h-[150px] lg:h-[200px] flex flex-col justify-center items-center"
        >
            <div className="h-12 w-12 rounded-full bg-links mx-auto flex justify-center items-center flex-shrink-0">
                <img src={icon} alt={`${title} logo`} />
            </div>
            <Typography.Body1 className="font-bold mt-4 !text-white">
                {title}
            </Typography.Body1>
            {subtitle && (
                <Typography.Body2 className="italic !font-normal !text-white">
                    {subtitle}
                </Typography.Body2>
            )}
        </ButtonCustomContent>
    )
}

const socialMedia: SocialMediaItemProps[] = [
    {
        icon: "/assets/images/logos/twitter-logo.svg",
        title: "Twitter",
        link: "https://twitter.com/horizenglobal",
    },
    {
        icon: "/assets/images/logos/discord-logo.svg",
        title: "Discord",
        link: "https://horizen.io/invite/discord",
    },
    {
        icon: "/assets/images/logos/telegram-logo.svg",
        title: "Telegram",
        link: "https://t.me/horizencommunity",
    },
    {
        icon: "/assets/images/logos/youtube-logo.svg",
        title: "YouTube",
        link: "https://youtube.com/horizen",
    },
    {
        icon: "/assets/images/logos/github-logo.svg",
        title: "Github",
        link: "https://github.com/horizenofficial",
    },
    {
        icon: "/assets/images/logos/twitter-logo.svg",
        title: "Twitter",
        link: "https://twitter.com/horizenZH",
        subtitle: "Chinese",
    },
    {
        icon: "/assets/images/logos/telegram-logo.svg",
        title: "Telegram",
        link: "https://t.me/horizencn",
        subtitle: "Chinese",
    },
    {
        icon: "/assets/images/logos/telegram-logo.svg",
        title: "Telegram",
        link: "https://t.me/horizenFR",
        subtitle: "French",
    },
    {
        icon: "/assets/images/logos/kakao-logo.svg",
        title: "Kakao",
        subtitle: "Korean",
        link: "https://open.kakao.com/o/gsve8JG",
    },
]

const CommunitySocialMedia = () => {
    return (
        <section className="lg:text-center">
            <ContentContainer>
                <Typography.H2>Follow Horizen Community Channels</Typography.H2>
                <Typography.Body1 className="mt-6">
                    Find your #zenfam on your favorite social channels
                </Typography.Body1>

                <div className="grid grid-cols-2 lg:grid-cols-4 gap-5 mt-10 lg:mt-20">
                    {socialMedia.map((item, index) => (
                        <SocialMediaItem
                            key={`${item.title}-${index}`}
                            icon={item.icon}
                            title={item.title}
                            subtitle={item.subtitle}
                            link={item.link}
                        />
                    ))}
                </div>
            </ContentContainer>
        </section>
    )
}

export default CommunitySocialMedia
