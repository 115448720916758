import React from "react"
import { twMerge } from "tailwind-merge"
import BoxWithLightOnTheBorder from "../../components/BoxWithLightOnTheBorder"
import Typography from "../../components/Typography"
import ContentContainer from "../../templates/ContentContainer"
import PodcastImg from "../../assets/BackgroundImages/Community/Horizen_Community_podcast.png"
import PodcastMobileImg from "../../assets/BackgroundImages/Community/Horizen_Community_podcast_mobile.png"

interface LearnMoreItemProps {
    image?: string
    imageMobile?: string
    alt?: string
    title: string
    cta: string
    link?: string
}

const LearnMoreItem: React.FC<LearnMoreItemProps> = ({
    image,
    imageMobile,
    alt,
    title,
    cta,
    link,
}) => {
    return (
        <a href={link} target="_blank" className="group" rel="noreferrer">
            <BoxWithLightOnTheBorder
                classname={twMerge(
                    "group-hover:border-links relative !p-[unset] max-w-[330px] lg:max-w-[unset] m-auto h-full min-h-[170px] lg:min-h-[150px]",
                    image && "min-h-[380px] lg:min-h-[300px]"
                )}
            >
                {image && (
                    <picture>
                        <source
                            media="(max-width: 1024px)"
                            srcSet={imageMobile}
                        />
                        <img
                            src={image}
                            alt={alt}
                            className="absolute inset-0 h-full w-full"
                        />
                    </picture>
                )}
                <Typography.H3 className="w-full max-w-[250px] h-[170px] lg:h-[150px] flex items-center pb-6 lg:pb-0 pl-7 lg:pl-12 absolute bottom-0">
                    {title}
                </Typography.H3>
                <Typography.Link1
                    href={link}
                    className="absolute left-7 bottom-7 lg:left-auto lg:bottom-5 lg:right-8"
                >
                    [ {cta} ]
                </Typography.Link1>
            </BoxWithLightOnTheBorder>
        </a>
    )
}

const CommunityLearnMore = () => {
    return (
        <section>
            <ContentContainer>
                <div className="grid lg:grid-cols-2 gap-5">
                    <div className="grid gap-5">
                        <LearnMoreItem
                            title="Stay Updated with the Latest News"
                            cta="Visit Horizen Newsroom"
                            link="https://horizen.io/news/"
                        />
                        <LearnMoreItem
                            title="Learn Everything about Web3"
                            cta="Visit Horizen Academy"
                            link="https://horizen.io/academy/"
                        />
                    </div>
                    <LearnMoreItem
                        title="Listen to Beyond the Horizen Podcast"
                        cta="Go to Podcast"
                        image={PodcastImg}
                        imageMobile={PodcastMobileImg}
                        alt="Podcast microphone"
                        link="https://podcast.horizen.io/"
                    />
                </div>
            </ContentContainer>
        </section>
    )
}

export default CommunityLearnMore
