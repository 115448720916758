import React from "react"
import Typography from "../../components/Typography"
import ContentContainer from "../../templates/ContentContainer"
import NewsletterForm from "../../components/NewsletterForm"
import { NEWSLETTER_DEVELOPER_LIST_ID } from "../../utils/constants"

const CommunityEarlyAdopters = () => {
    return (
        <section>
            <ContentContainer>
                <p className="font-bold font-proxima text-xl tracking-[0.3rem] text-links uppercase">
                    WEB3 DEVELOPERS{" "}
                </p>
                <Typography.H2 className="mt-3">
                    Be an Early Adopter
                </Typography.H2>
                <div className="grid lg:grid-cols-2 gap-x-5 mt-3">
                    <div className="flex flex-col justify-between">
                        <Typography.Body1 className="max-w-[450px]">
                            Gain exclusive and early access to Horizen&apos;s
                            newest releases and product features before they are
                            publicly available.
                        </Typography.Body1>
                    </div>
                    <Typography.Body1>
                        <ul className="list-disc list-inside mt-3 lg:mt-0 pl-4 lg:pl-0">
                            <li>
                                Early insight into new technology and products
                            </li>
                            <li>Earn rewards by testing products</li>
                            <li>
                                Play a pivotal role in shaping feature designs
                                and product functionality
                            </li>
                            <li>
                                Developer tutorials and other free learning
                                materials
                            </li>
                            <li>
                                Invite only webinars and pre-release product
                                demos
                            </li>
                        </ul>
                    </Typography.Body1>
                </div>
                {process.env.GATSBY_NEWSLETTER_SIGNUP_WORKER_URL && (
                    <>
                        <Typography.H3 className="mt-16 lg:mt-24 mb-6 lg:mb-0 text-center">
                            JOIN OUR DEVELOPER MAILING LIST
                        </Typography.H3>
                        <div className="w-full lg:w-2/3 m-auto">
                            <NewsletterForm
                                newsletterID={NEWSLETTER_DEVELOPER_LIST_ID}
                                inputsOnly
                            />
                        </div>
                    </>
                )}
            </ContentContainer>
        </section>
    )
}

export default CommunityEarlyAdopters
